import { PageProps } from "gatsby";
import React from "react";

import { AccidentalSigns } from "../../components/pages/AccidentalSigns";

import { dnProps } from "../../js/utils";

const AccidentalSignsPage = (props: PageProps) => (
  <AccidentalSigns {...{ ...dnProps, ...props }} />
);

export default AccidentalSignsPage;
